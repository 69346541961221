import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";


export const Recharge = () => {
  const { chain } = useAccount();
  const {  onCharge, onChargeReborn, onChargePendingRewards } = useStake();
  const { address, isConnected } = useAccount();
  const { frbBalance, unCharged, rewardPoints, chargeAmount, chargeReborn, pendingRewards} = useBalances();
  const submitButtonRef = useRef(null);

  const [{ isStake, placeholder }, setOption] = useState({
    isStake: true,
    placeholder: "Amount of Flare Reborn NFTs",
  });
  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const [{ stakeInput, unstakeInput, stakeInputReborn }, setInput] = useState({
    stakeInput: "",
    unstakeInput: "",
    stakeInputReborn:"",
  });
  {/*useEffect(() => {
    submitButtonRef.current &&
      (() => {
        submitButtonRef.current.disabled = true;

        if (isStake) {
          if (rewardPoints == 0 || stakeInput > parseFloat(unCharged).toFixed(0) || (parseInt(chargeAmount)*parseInt(stakeInput)) > rewardPoints ) {
            submitButtonRef.current.innerHTML = "insufficient balance";
            return;
          }
          if (stakeInput) {
            submitButtonRef.current.innerHTML = "Recharge";
            submitButtonRef.current.disabled = false;
            return;
          }
          submitButtonRef.current.innerHTML = "Enter quantity";

          return;
        }

        submitButtonRef.current.innerHTML = "Enter quantity";
          return;
      })();
  }, [isStake, stakeInput, unstakeInput, balance, stakeBalance, cnyxBalance, unCharged, charged, stakeInputReborn]);*/}

  const onInputChange = (event) => {
    const value = event.target.valueAsNumber;
    const _stakeInput = isStake ? value : stakeInput;
    setInput({
      stakeInput: _stakeInput,
      unstakeInput:_stakeInput,
    });
  };
  const onInputChangeReborn = (event) => {
    const value = event.target.valueAsNumber;
    const _stakeInput = isStake ? value : stakeInputReborn;
    setInput({
      stakeInputReborn: _stakeInput,
      unstakeInput:_stakeInput,
    });
  };

  const onMaxBalance = () => {
    var balance = 0;
    if(parseInt(unCharged) > 0){
      balance = parseInt(unCharged);
    }

    if (isStake)
      return setInput((prev) => ({
        ...prev,
        stakeInputReborn: balance,
      }));
    setInput((prev) => ({
      ...prev,
      unstakeInputReborn: balance,
    }));
  };

  const onMaxBalanceReborn = () => {
    var tmpBalance = 0;
    if(unCharged > 0 && chargeReborn > 0 && (parseInt(frbBalance) >=(parseInt(unCharged)*parseInt(chargeReborn)))){
      tmpBalance = parseInt(unCharged);
    }
    if(unCharged > 0 && parseInt(frbBalance) < (parseInt(unCharged)*parseInt(chargeReborn))){
      tmpBalance = Math.floor(parseInt(parseFloat((parseInt(frbBalance)/parseInt(chargeReborn)))));
    }
    if (isStake)
      return setInput((prev) => ({
        ...prev,
        stakeInputReborn: tmpBalance,
      }));
    setInput((prev) => ({
      ...prev,
      unstakeInput: tmpBalance,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isConnected) return;
    if (isStake)
      return toast.promise(onCharge(stakeInput), {
        loading: "Transaction is in progress",
        success: "Successfully charged Flare Reborn NFTs",
        error: (data) => data,
      });
  };

  const handleSubmitReborn = async (event) => {
    event.preventDefault();
    if (!isConnected) return;
    if (isStake)
      return toast.promise(onChargeReborn(stakeInputReborn), {
        loading: "Transaction is in progress",
        success: "Successfully charged Flare Reborn NFTs",
        error: (data) => data,
      });
  };
  const chargePending = async (event) => {
    event.preventDefault();
    if (!isConnected) return;
    if (isStake)
      return toast.promise(onChargePendingRewards(stakeInputReborn), {
        loading: "Transaction is in progress",
        success: "Successfully charged Flare Reborn NFTs",
        error: (data) => data,
      });
  };


  return (
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8" py-5>
      <div className= "py-2 flex justify-center">
       <h2 className="text-foreground text-2xl font-medium flex gap-2 py-1">
                 Flare Reborn NFT
      </h2>
      </div>
       <div className="flex justify-center">
       <img src={process.env.PUBLIC_URL + 'CRF_KEY.gif'} width="350" heigh="350" style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }} />
       </div>

      <form onSubmit={handleSubmitReborn}>
        <div className="mt-8 p-1 pr-0">
          <div className="flex w-full">
            <input
              value={stakeInputReborn}
              max={parseFloat(unCharged).toFixed(0)}
              onChange={onInputChangeReborn}
              placeholder={placeholder}
              className="text-foreground font-medium w-[80%] rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />

            <span className="w-1/5">
              <Button
                type="button"
                onClick={onMaxBalance}
                className="rounded-tl-none rounded-bl-none"
              >
                max
              </Button>
            </span>
          </div>
        </div>
        <div className="flex justify-center py-1 text-foreground/50">
           <p><b>Rechargeable Flare Reborn NFTs:</b> {parseFloat(unCharged).toFixed(0)}</p>
        </div>
        <div className="flex justify-center py-1 text-foreground/50">
           {unCharged > 0 && <p><b>FRB:</b> {parseFloat(frbBalance).toFixed(2)}/{parseInt(stakeInputReborn) * parseInt(chargeReborn)} ({chargeReborn} each)</p>}
        </div>
      

        <div className="mt-10 py-2">
        
          <Button type="submit" disabled={!isCorrectChain || unCharged==0 || unCharged < stakeInputReborn || frbBalance < Math.floor((parseInt(stakeInputReborn)*parseInt(chargeReborn))) || stakeInputReborn==0}>Recharge with FRB</Button>
          
        </div>
      </form>
      <div className="mt-10 py-2">
        
        <Button type="submit" onClick={chargePending} disabled={!isCorrectChain || unCharged==0 || unCharged < stakeInputReborn || pendingRewards < Math.floor((parseInt(stakeInputReborn)*parseInt(chargeReborn))) || stakeInputReborn==0}>Recharge with pending rewards</Button>
        
      </div>
    </div>
  );
};
