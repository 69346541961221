import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from "wagmi";
import { etherUnits, getContract, maxUint256, parseEther } from "viem";
import {
  CANARY_DAO_ABI,
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  REWARD_ABI,
  REWARD_CONTRACT_ADDRESS,
  FARM_ABI,
  FARM_ADDRESS,
  LP_CONTRACT_ADDRESS,
  VOTE_ABI,
  VOTE_ADDRESS,
  REBORN_DAO_ABI,
  DAO_REBORN_ADDRESS,
  DAO_VOTE_ABI,
  DAO_VOTE_ADDRESS,
  AIRDROP_ABI,
  AIRDROP_ADDRESS,
} from "@/src/lib/constants";
import { useBalances } from "./useBalances";
import { useDaoVotes, useVotes } from "./useVotes";
import { useRebase } from "../context";
import { useAirdrop } from "./useMetrics";

export const useStake = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useAccount();
  const { address } = useAccount();
  const { getBalances, rewardReborn, chargeReborn } = useBalances();
  const { getVotes} = useVotes();
  const { getDaoVotes, voteAmount} = useDaoVotes();
  const { getRebaseInfo } = useRebase();
  const { getAirdrop } = useAirdrop();
  //const { useFaucet } = onUseFaucet();

  const canyContractWrite = getContract({
    abi: CANARY_DAO_ABI,
    address: DAO_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const tokenContractWrite = getContract({
    abi: ERC20_ABI,
    address: ERC20_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const lpContractWrite = getContract({
    abi: ERC20_ABI,
    address: LP_CONTRACT_ADDRESS,
    client: walletClient,
  });

  const nftcontractWrite = getContract({
    abi: NFT_ABI,
    address: NFT_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const rewardContractWrite = getContract({
    abi: REWARD_ABI,
    address: REWARD_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const farmContractWrite = getContract({
    abi: FARM_ABI,
    address: FARM_ADDRESS,
    client: walletClient,
  });
  const voteContractWrite = getContract({
    abi: VOTE_ABI,
    address: VOTE_ADDRESS,
    client: walletClient,
  });
  const daoRebornContractWrite = getContract({
    abi: REBORN_DAO_ABI,
    address: DAO_REBORN_ADDRESS,
    client: walletClient,
  });
  const daoVoteContract = getContract({
    abi: DAO_VOTE_ABI,
    address: DAO_VOTE_ADDRESS,
    client: walletClient,
  });
  const airdropContract = getContract({
    abi: AIRDROP_ABI,
    address: AIRDROP_ADDRESS,
    client: walletClient,
  });
  const onMintReward = async() => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, REWARD_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(rewardReborn.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [REWARD_CONTRACT_ADDRESS, parseEther(rewardReborn.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 1,
          hash:hash.toString()

        });
      }

      const hash = await rewardContractWrite.write.mintReward([NFT_CONTRACT_ADDRESS],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onMintRewardPendingRewards = async() => {
    try {
      const hash = await canyContractWrite.write.enableRewardClaiming(
        [],
        {
          from: address,
          chain,
        }
      );
      
      
      await publicClient.waitForTransactionReceipt({
        confirmations: 4,
        hash:hash.toString()

      });

      const hash2 = await rewardContractWrite.write.mintRewardPendingRewards([NFT_CONTRACT_ADDRESS],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash2.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onChargeReborn = async(stakeInputReborn) => {
   try{
      var stakeAllowance = parseInt(stakeInputReborn)*parseInt(chargeReborn);
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, REWARD_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(stakeAllowance.toString()) || allowance == 0) {
        const hash = await tokenContractWrite.write.approve(
          [REWARD_CONTRACT_ADDRESS, parseEther(stakeAllowance.toString())],
          {
            from: address,
            chain,
          }
        );
        
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 1,
          hash:hash.toString()

        });
      }
      const hash = await rewardContractWrite.write.chargeNftReborn(
        [stakeInputReborn],{
        from: address,
        chain,
      });
      
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      
      getBalances(address);
      return;
    } catch (err) {
      getBalances(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onChargePendingRewards = async(stakeInputReborn) => {
    try{

         const hash = await canyContractWrite.write.enableRewardClaiming(
           [],
           {
             from: address,
             chain,
           }
         );
         
         
         await publicClient.waitForTransactionReceipt({
           confirmations: 4,
           hash:hash.toString()
 
         });
  
       const hash2 = await rewardContractWrite.write.chargeNftPendingRewards(
         [stakeInputReborn],{
         from: address,
         chain,
       });
       
       await publicClient.waitForTransactionReceipt({
         hash:hash2.toString()
       });
       
       getBalances(address);
       return;
     } catch (err) {
       getBalances(address);
       throw (
         err.shortMessage || "An error occurred while executing the transaction"
       );
      }
   };
  const onStake = async (stakeInput) => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: LP_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, DAO_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(stakeInput.toString())) {
        const hash = await lpContractWrite.write.approve(
          [DAO_CONTRACT_ADDRESS, parseEther(stakeInput.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await canyContractWrite.write.stake(
        [parseEther(stakeInput.toString())],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onUnstake = async (unstakeInput) => {
    try {
      const hash = await canyContractWrite.write.unStake(
        [parseEther(unstakeInput.toString())],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onHarvest = async () => {
    try {
      const hash = await canyContractWrite.write.harvest(
        [],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      //window.location.reload(false);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onClaimPendingRewards = async () => {
    try {
      const hash = await canyContractWrite.write.claimPendingRewards(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onClaimAsAirdrop = async () => {
    try {
      const hash = await canyContractWrite.write.claimAsAirdrop(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onClaimAirdrop = async () => {
    try {
      const hash = await airdropContract.write.claimAirdrop(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getAirdrop(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onVote = async(voteInput) => {
    try{
      const hash = await voteContractWrite.write.castVote(
        [voteInput],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getVotes(address);
      return;
    } catch (err) {
      getVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onProposeSupply = async(amount) => {
    try{
      const hash = await tokenContractWrite.write.proposeIncreasedSupply(
        [parseFloat(amount).toFixed(0)*10**18],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getVotes(address);
      return;
    } catch (err) {
      getVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onIncreaseSupply = async() => {
    try{
      const hash = await tokenContractWrite.write.IncreaseSupply(
        [],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getVotes(address);
      return;
    } catch (err) {
      getVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onCancelVote = async() => {
    try{
      const hash = await tokenContractWrite.write.cancelVote(
        [],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getVotes(address);
      return;
    } catch (err) {
      getVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onDaoVote = async(voteInput) => {
    try{
      const hash = await daoVoteContract.write.castVote(
        [voteInput],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getDaoVotes(address);
      return;
    } catch (err) {
      getDaoVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onProposeChange = async(_function, var1, var2, var3, var4) => {
    try{
      console.log(typeof _function);
      console.log(typeof var1);
      console.log(typeof var2);
      console.log(typeof var3);
      console.log(typeof var4);
  
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, DAO_REBORN_ADDRESS],
      });

      if (allowance < parseEther(voteAmount.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [DAO_REBORN_ADDRESS, parseEther(voteAmount.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }
    
      console.log("begin checks");
      if(typeof var1==='undefined'){
        var1="0";
      }
      console.log("var1 ok");
      if(typeof var2==='undefined'){
        var2="0";
      }
      console.log("var2 ok");
      if(typeof var3==='undefined' || var3==""){
        var3="0x0000000000000000000000000000000000000000";
      }
      console.log("var3 ok");
      if(typeof var4==='undefined' || var4==""){
        var4="0x0000000000000000000000000000000000000000";
      }
      console.log("var4 ok");
      const hash2 = await daoRebornContractWrite.write.proposeVote(
        [_function, var1, var2, var3, var4],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash2.toString()
      });
      getDaoVotes(address);
      return;
    } catch (err) {
      getDaoVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onExecuteVote = async() => {
    try{
      const hash = await daoRebornContractWrite.write.executeVote(
        [],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getDaoVotes(address);
      return;
    } catch (err) {
      getDaoVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onCancelDaoVote = async() => {
    try{
      const hash = await daoRebornContractWrite.write.cancelVote(
        [],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getDaoVotes(address);
      return;
    } catch (err) {
      getDaoVotes(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };


  return {onStake, onUnstake, onMintReward, onChargeReborn, onClaimPendingRewards, onClaimPendingRewards, onHarvest, onChargePendingRewards, onMintRewardPendingRewards, onVote, onProposeSupply, onIncreaseSupply, onCancelVote, onProposeChange, onExecuteVote, onCancelDaoVote, onDaoVote,onClaimAirdrop,onClaimAsAirdrop};
};
